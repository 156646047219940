import { computed, Injectable, signal, WritableSignal } from '@angular/core';
import {
  IHeaderSearchInput,
  IHeaderSearchState,
} from '@app/shared/models/header-search-model';
import {
  SELECTEDREPORT_CTMR,
  SELECTEDREPORT_CTSP,
  SELECTEDREPORT_MRSP,
  SERVICE_APPLICATION_AREA,
} from '@app/shared/utils/layout-util';
import { Ichildren } from '@layout/models/hospitalList-model';

@Injectable({
  providedIn: 'root',
})
export class HeaderSearchService {
  private readonly currentSearchInput: WritableSignal<IHeaderSearchInput> =
    signal<IHeaderSearchInput>({
      hospitalID: '',
    } as IHeaderSearchInput);

  protected initialState: WritableSignal<IHeaderSearchState> =
    signal<IHeaderSearchState>({
      hospital: {
        id: '',
        hash: '000000000000000000000000000000000000',
        name: '',
      },
      report: {
        id: 'CT',
        name: '',
      },
      date: {
        fromDate: new Date(new Date().getFullYear(), 0, 1),
        toDate: new Date(),
      },
      hospitalName: '',
      network_id: '',
      hospital_id: '',
      location_id: '',
      lastNodeId: '',
      all_level_id: '',
      hospitalOptions: [],
      reportTranslations: {
        CT: 'CT-SP',
        'CT-MP': 'CT-MP',
        MR: 'MR-SP',
        ALL: 'ALL',
        ANGIO: 'ANGIO',
        MI: 'MI',
      },
      reportApplicationArea: SERVICE_APPLICATION_AREA,
      benchmarkrows: 0,
      benchmarkrowsMr: 0,
      benchmarkrowsMRMP: 0,
      benchmarkrowsCtmp: 0,

      helpTourStatus: false,
      injectorOptions: [],
      injector: '',
    });
  private readonly updateLanguage: WritableSignal<string> = signal('');
  private readonly isUpdateUserDetails: WritableSignal<boolean> = signal<boolean>(false);
  protected toDate: WritableSignal<Date> = signal<Date>(new Date());
  protected hospitalOptions: WritableSignal<Ichildren[]> = signal<Ichildren[]>(
    []
  );
  protected kpiLoadingCount: WritableSignal<number> = signal<number>(0);

  public applicationArea = computed(() => {
    return this.currentSearchInput().reportView;
  });
  constructor() { }

  public setHosptalOptions(hospitalOptions: Ichildren[]) {
    this.initialState.update((items) => ({
      ...items,
      hospitalOptions: hospitalOptions,
    }));
  }

  public updateHospitalLocation(id: string, location: string, url: string) {
    this.currentSearchInput.update((stateInput) => ({
      ...stateInput,
      hospitalID: id,
      location: location,
      path: url,
    }));
  }

  public updateApplicationArea(area: string, url: string) {
    this.currentSearchInput.update((stateInput) => ({
      ...stateInput,
      reportView: area,
      path: url,
    }));
  }

  public updateReportSearchOptions(id: string, areaID: string, url: string) {
    this.currentSearchInput.update((stateInput) => ({
      ...stateInput,
      hospitalID: id,
      reportView: areaID,
      path: url,
    }));
  }

  public updateDate(fromDate: string, toDate: string, url: string) {
    this.currentSearchInput.update((stateInput) => ({
      ...stateInput,
      fromDate: fromDate,
      toDate: toDate,
      path: url,
    }));

    this.toDate.update((inputData) => new Date(toDate));

    this.initialState.update((stateInput) => ({
      ...stateInput,
      date: { fromDate: new Date(fromDate), toDate: new Date(toDate) },
    }));
  }

  public updateUrl(url: string) {
    this.currentSearchInput.update((stateInput) => ({
      ...stateInput,
      path: url,
    }));
  }
  public updateReportID(selectedReportID: string) {
    this.initialState.update((input) => ({
      ...input,
      report: { id: selectedReportID, name: selectedReportID },
    }));
  }

  public get headerSearchInput() {
    return this.currentSearchInput;
  }
  public get getUpdatedLanguageSignal() {
    return this.updateLanguage;
  }
  public set setUpdatedLanguageSignal(value: string) {
    this.updateLanguage.update(oldValue => value);
  }
  private findId(id: string, arr: Ichildren[]): Ichildren {
    return arr.reduce((a: any, item: Ichildren) => {
      if (a) return a;
      if (item.value === id) return item;
      if (item.children) return this.findId(id, item.children);
    }, null);
  }

  private findHospitalId(id: string, arr: Ichildren[]): Ichildren {
    return arr.reduce((a: any, item: Ichildren) => {
      if (item?.level?.toLocaleUpperCase() !== 'INJECTOR') {
        if (a) return a;
        if (item.value === id) return item;
        if (item.children) return this.findHospitalId(id, item.children);
      }
    }, null);
  }

  public changeHospitalHelper(actiondata: any) {
    const hospitalOptions = this.initialState().hospitalOptions;
    const benchmarkArray: number[] = [];
    const benchmarkMrArray: number[] = [];
    const benchmarkMRMPArray: number[] = [];
    const benchmarkrowsCtmpArray: number[] = [];
    const hospitalNameArray: string[] = [];
    const networkIdArray: string[] = [];
    const hospitalIdArray: string[] = [];
    const locationIdArray: string[] = [];
    const injectorArray: string[] = [];
    const level4: string[] = [];
    const level5: string[] = [];
    const level6: string[] = [];
    const level7: string[] = [];

    const splitedID =
      actiondata.hospital.id.indexOf(',') > -1
        ? actiondata.hospital.id.split(',')
        : [actiondata.hospital.id];
    const set1 = new Set<string>(splitedID);

    const All_Ids_Array = Array.from(set1).sort((a: any, b: any) => a - b);
    let selectedInjector: string[] = [];
    All_Ids_Array.forEach((_id) => {
      const result = this.findHospitalId(_id, hospitalOptions);
      if (result) {
        selectedInjector.push(result.value);
      }
    });

    const all_ids = selectedInjector.join(',');

    All_Ids_Array.forEach((_id) => {
      const result = this.findId(_id, hospitalOptions);

      if (result) {
        hospitalNameArray.push(result.label);
        benchmarkArray.push(result.benchmarkrows);
        benchmarkMrArray.push(result.benchmarkrowsMr);
        benchmarkMRMPArray.push(result?.benchmarkrowsMrmp as number);
        benchmarkrowsCtmpArray.push(result.benchmarkrowsCtmp);

        switch (result.level?.toLocaleUpperCase()) {
          case 'NETWORK':
            networkIdArray.push(result.value);
            break;
          case 'HOSPITAL':
            hospitalIdArray.push(result.value);
            break;
          case 'LOCATION':
            locationIdArray.push(result.value);
            break;
          case 'INJECTOR':
            injectorArray.push(result.value);
            break;
          case 'LEVEL4':
            level4.push(result.value);
            break;
          case 'LEVEL5':
            level5.push(result.value);
            break;
          case 'LEVEL6':
            level6.push(result.value);
            break;
          case 'LEVEL7':
            level7.push(result.value);
            break;
          default:
            console.error(`Sorry, we are out of ${result.level}.`);
        }
      }
    });

    let benchmarkrows = 0;
    if (benchmarkArray && benchmarkArray.length > 0) {
      benchmarkrows = benchmarkArray.reduce(function (a, b) {
        return a + b;
      }, 0);
      benchmarkrows /= benchmarkArray.length;
    }

    let benchmarkrowsMr = 0;
    if (benchmarkMrArray && benchmarkMrArray.length > 0) {
      benchmarkrowsMr = benchmarkMrArray.reduce(function (a, b) {
        return a + b;
      }, 0);
      benchmarkrowsMr /= benchmarkMrArray.length;
    }

    let benchmarkrowsMrMP = 0;
    if (benchmarkMRMPArray && benchmarkMRMPArray.length > 0) {
      benchmarkrowsMrMP = benchmarkMRMPArray.reduce(function (a, b) {
        return a + b;
      }, 0);
      benchmarkrowsMrMP /= benchmarkMRMPArray.length;
    }

    let benchmarkrowsCtmp = 0;
    if (benchmarkrowsCtmpArray && benchmarkrowsCtmpArray.length > 0) {
      benchmarkrowsCtmp = benchmarkrowsCtmpArray.reduce(function (a, b) {
        return a + b;
      }, 0);
      benchmarkrowsCtmp /= benchmarkrowsCtmpArray.length;
    }

    let hash = this.simpleHash(all_ids || ' ');

    this.initialState().hospital = { id: all_ids, hash };
    this.initialState().hospitalName = hospitalNameArray.join(',');
    this.initialState().network_id = networkIdArray.join(',');
    this.initialState().hospital_id = hospitalIdArray.join(',');
    this.initialState().location_id = locationIdArray.join(',');
    this.initialState().injector = All_Ids_Array.join(',');
    this.initialState().all_level_id = all_ids;
    this.lengthChecks(
      hospitalIdArray,
      locationIdArray,
      level4,
      level5,
      level6,
      level7
    );
    this.benchArrayChecks(
      benchmarkrows,
      benchmarkrowsMr,
      benchmarkrowsCtmp,
      benchmarkrowsMrMP
    );
  }

  lengthChecks(
    hospitalIdArray: string[],
    locationIdArray: string[],
    level4: string[],
    level5: string[],
    level6: string[],
    level7: string[]
  ) {
    if (level7.length > 0) {
      this.initialState().lastNodeId = level7.join();
    } else if (level6.length > 0) {
      this.initialState().lastNodeId = level6.join();
    } else if (level5.length > 0) {
      this.initialState().lastNodeId = level5.join();
    } else if (level4.length > 0) {
      this.initialState().lastNodeId = level4.join();
    } else if (locationIdArray.length > 0) {
      this.initialState().lastNodeId = locationIdArray.join();
    } else if (hospitalIdArray.length > 0) {
      this.initialState().lastNodeId = hospitalIdArray.join();
    }
  }
  benchArrayChecks(
    benchmarkrows: number,
    benchmarkrowsMr: number,
    benchmarkrowsCtmp: number,
    benchmarkrowsMrMP: number
  ) {
    if (benchmarkrows) {
      this.initialState().benchmarkrows = benchmarkrows;
    }
    if (benchmarkrowsMr) {
      this.initialState().benchmarkrowsMr = benchmarkrowsMr;
    }

    if (benchmarkrowsCtmp) {
      this.initialState().benchmarkrowsCtmp = benchmarkrowsCtmp;
    }

    if (benchmarkrowsMrMP) {
      this.initialState().benchmarkrowsMRMP = benchmarkrowsMrMP;
    }
  }
  public simpleHash(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash &= hash;
    }
    return new Uint32Array([hash])[0].toString(36);
  }

  public makeSelectHashSelection() {
    return this.simpleHash(
      `${this.initialState().hospital.id}-${this.initialState().report.id}-${this.initialState().date.toDate
      }-${this.initialState().date.fromDate}`
    );
  }

  public makeSelectHospital() {
    return this.initialState().hospital;
  }

  public makeSelectInjector() {
    return this.initialState().injector;
  }

  public makeSelectLocationId() {
    return this.initialState().location_id;
  }

  public makeSelectHospital_Id() {
    return this.initialState().hospital_id;
  }

  public makeSelectHospitalOptions() {
    return this.initialState().hospitalOptions;
  }

  public makeSelectInjectorOptions() {
    return this.initialState().injectorOptions;
  }

  public makeSelectNetworkId() {
    return this.initialState().network_id;
  }

  public makeSelectAllLevelId() {
    return this.initialState().all_level_id;
  }
  public makeSelectLastNodeId() {
    return this.initialState().lastNodeId;
  }

  public makeSelectReport() {
    return this.initialState().report;
  }
  public makeSelectReportOptions() {
    return this.initialState().reportTranslations;
  }

  public makeSelectedHospitalNames() {
    return this.initialState().hospitalName;
  }

  public filterJsonArray(id: string, arr: Ichildren[]): Ichildren[] {
    const result = arr.map((data) => {
      return this.filterJson(id, [data]) as Ichildren;
    });

    if (arr.length > 0) {
      const resultHospital = arr[0]?.children?.map((data) => {
        return this.filterJson(id, [data]) as Ichildren;
      });

      const combineArray = [
        ...new Set(result.concat(resultHospital as Ichildren[])),
      ];
      const filterNullResult = combineArray.filter((e) => e !== undefined && e !== null);

      return filterNullResult;
    }
    return result;
  }

  private filterJson(id: string, arr: Ichildren[]): Ichildren | null {
    let searchString = id.toLowerCase();

    return arr?.reduce<Ichildren | null>((a, item) => {
      if (a) {
        return a;
      }

      if (
        item.value.toLowerCase() === searchString ||
        item.label.toLowerCase().includes(searchString)
      ) {
        return item;
      }
      if (item.children) {
        const result = this.filterJson(searchString, item.children);
        if (result) {
          return result;
        }
      }
      return null;
    }, null);
  }

  public makeSelectDate() {
    let dates = { ...this.initialState().date };
    dates.toDate = this.checkDate(dates.toDate) ? dates.toDate : new Date();
    dates.fromDate = this.checkDate(dates.toDate) ? dates.fromDate : new Date();
    return dates;
  }

  public makeSelectLocationNames() {
    const locations = this.initialState().location_id.split(',');
    const locationNames = locations.join(',');
    return locationNames;
  }

  public makeSelectBenchmarkrows() {
    return this.initialState().benchmarkrows;
  }

  public makeSelectBenchmarkrowsMr() {
    return this.initialState().benchmarkrowsMr;
  }

  public makeSelectBenchmarkrowsMRMP() {
    return this.initialState().benchmarkrowsMRMP;
  }

  public makeSelectBenchmarkrowsCtmp() {
    return this.initialState().benchmarkrowsCtmp;
  }

  public makeSelectSelectionPanel() {
    return this.initialState();
  }

  public makeSelectValidSelection() {
    const hospitalID = this.initialState().hospital?.id;
    const reportID = this.initialState().report?.id;
    if (
      this.initialState().hospital &&
      hospitalID &&
      this.initialState().report &&
      reportID &&
      this.initialState().location_id &&
      this.initialState().hospital_id
    ) {
      return true;
    }
    return false;
  }

  private checkDate(dates: Date) {
    const year = dates?.getFullYear();
    if (dates && year) {
      return true;
    }
    return false;
  }

  public checkSearchInput(value: IHeaderSearchInput) {
    if (
      !(
        value.fromDate &&
        value.hospitalID &&
        value.path &&
        value.toDate &&
        value.reportView
      )
    ) {
      return true;
    }
    return false;
  }

  public get getSelectedDate() {
    return this.toDate;
  }

  isObjectEmptyNot(input: any) {
    if (!input) {
      return false;
    }
    if (input.length > 1) {
      return Object.keys(input[0]).length > 0;
    }
    return Object.keys(input).length > 0;
  }

  getselectedBenchMark() {
    const reportID = this.makeSelectReport().id;

    if (reportID === SELECTEDREPORT_CTSP) {
      return this.makeSelectBenchmarkrows();
    }

    if (reportID === SELECTEDREPORT_CTMR) {
      return this.makeSelectBenchmarkrowsCtmp();
    }

    if (reportID === SELECTEDREPORT_MRSP) {
      return this.makeSelectBenchmarkrowsMr();
    }

    return this.makeSelectBenchmarkrowsMRMP();
  }

  resetKPILoadingCount() {
    this.kpiLoadingCount.set(0);
  }

  updateKPILoadingCount() {
    this.kpiLoadingCount.update(oldValue => ++oldValue);
  }

  public get getKPILoadingCount() {
    return this.kpiLoadingCount;
  }
  public get getUserUpdatedDetails() {
    return this.isUpdateUserDetails;
  }
  public set setUserUpdatedDetails(status: boolean) {
    this.isUpdateUserDetails.set(status);
  }

}
