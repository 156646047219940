import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { IHospitalProtocol } from '@app/shared/models/key-indicator-model';
import { IUserOption } from '@app/shared/models/role-model';
import { API_NAME } from '@app/shared/utils/api-util';
import {
  CONVERSION_USER_ROLE,
  REGIONS_NAME,
  USER_ROLE,
  USER_ROLE_OPTION,
} from '@app/shared/utils/common-content-util';
import { IUserProfile } from '@layout/models/layout-model';
import { catchError, map, of } from 'rxjs';
import { HeaderSearchService } from '../header-search/header-search.service';

@Injectable({
  providedIn: 'root',
})
export class RolePermissionService {
  protected role: WritableSignal<string> = signal<string>('');
  protected country: WritableSignal<string> = signal<string>('');
  protected userProfile: WritableSignal<IUserProfile> = signal<IUserProfile>(
    {} as IUserProfile
  );
  protected allowedSubregions: WritableSignal<string[]> = signal<string[]>([]);
  protected permission: WritableSignal<string[]> = signal<string[]>([]);
  protected allowedRegions: WritableSignal<string[]> = signal<string[]>([]);
  protected protocolList: WritableSignal<IHospitalProtocol[]> = signal<IHospitalProtocol[]>([]);

  protected regionWithShortName: any = REGIONS_NAME;
  private readonly httpClient = inject(HttpClient);
  private readonly headerSearch = inject(HeaderSearchService);

  protected permissionObseration$ = this.httpClient
    .get(API_NAME.permission_matrix)
    .pipe(
      map((result: any) => {
        this.permission.set(result?.permissions);
        this.setUserProfile({
          email: result?.User_Federation_ID,
          firstName: result?.First_Name,
          lastName: result?.Last_Name,
        } as IUserProfile);
        this.role.set(result?.User_Role);
        this.country.set(result?.Country);
        this.setAllowedRegions(result?.allowed_regions);
        this.setAllowedSubregions(result?.Regional_Admin_Countries);
        return [] as string[];
      })
    );

  constructor() { }

  setPermissionDetails() {
    const permission = this.getPermission();
    if (this.isCheckObject(permission)) {
      return of(permission);
    }
    return this.permissionObseration$;
  }

  getUserRole() {
    return this.role();
  }

  getUserProfile() {
    return this.userProfile();
  }

  setUserProfile(user: IUserProfile) {
    this.userProfile.set(user);
  }

  setCountry(country: string) {
    this.country.set(country);
  }

  setAllowedRegions(regions: string[]) {
    this.allowedRegions.set(regions);
  }

  setAllowedSubregions(subregions: string[]) {
    this.allowedSubregions.set(subregions);
  }

  setUserRole(role: string) {
    this.role.set(role);
  }

  setPermission(permission: string[]) {
    this.permission.set(permission);
  }
  getPermission() {
    return this.permission();
  }

  getAllowedRegion() {
    return this.allowedRegions();
  }

  getAllowedSubregions() {
    return this.allowedSubregions();
  }

  getCountry() {
    return this.country();
  }

  isCheckObject(input: string[]) {
    return input.length > 0;
  }

  public set setProtocol(protocols: IHospitalProtocol[]) {
    this.protocolList.set(protocols);
  }
  public get getProtocol() {
    return this.protocolList;
  }



  getSelectedProtocolWithoutDuplicate() {
    let selectedList: string[] = [];

    this.protocolList()?.map(v => {
      if (v.selected_protocols?.trim()) {
        selectedList.push(...v.selected_protocols.trim().split(','));
      }
    });
    let protocolString = selectedList.join(',')
    return [...new Set([...protocolString.split(',')])];

  }

  private unSelectedProtocol(selectedProtocol: string, protocolList: IHospitalProtocol[]) {
    let protocols = [...protocolList];

    const filtedProtocols = protocols.map((protocol) => {
      return {
        hospital_id: protocol.hospital_id,
        selected_protocols: this.removeProtocolFromArray(protocol?.selected_protocols, selectedProtocol),
        unselected_protocols: this.addProtocolFromArray(protocol?.unselected_protocols, selectedProtocol)
      } as IHospitalProtocol;
    });
    return filtedProtocols;
  }

  private selectedProtocols(selectedProtocol: string, protocolList: IHospitalProtocol[]) {
    let protocols = [...protocolList];
    if (protocols.length === 0) {
      return [{
        hospital_id: this.headerSearch.makeSelectLastNodeId(),
        selected_protocols: selectedProtocol,
        unselected_protocols: ''
      } as IHospitalProtocol]
    }
    const filtedProtocols = protocols.map((protocol) => {
      return {
        hospital_id: protocol.hospital_id,
        selected_protocols: this.addProtocolFromArray(protocol?.selected_protocols, selectedProtocol),
        unselected_protocols: this.removeProtocolFromArray(protocol?.unselected_protocols, selectedProtocol)

      } as IHospitalProtocol;
    });
    return filtedProtocols;
  }

  public filterByProtocols(url: string, selectedProtocol?: string, unSelectedProtocol?: string) {
    let currentProtocol = [...this.protocolList()]
    if (typeof (selectedProtocol) !== 'undefined') {
      const protocol = this.selectedProtocols(selectedProtocol, currentProtocol);
      currentProtocol = [...protocol];
    }
    if (typeof (unSelectedProtocol) !== 'undefined') {
      const protocol = this.unSelectedProtocol(unSelectedProtocol, currentProtocol);
      currentProtocol = [...protocol];
    }
    return this.saveSelectedProtocols(currentProtocol, url)
      .pipe(map((result: any) => {
        if (result?.data?.saveProtocol) {
          this.setProtocol = currentProtocol;
        }
        return result?.data?.saveProtocol;
      }),
        catchError((error: any) => {
          return of(false);
        })
      );
  }

  private saveSelectedProtocols(currentProtocol: IHospitalProtocol[], url: string) {
    let selectedprotocols: string[] = [];
    currentProtocol?.forEach(
      (hospital: IHospitalProtocol) => {
        if (hospital?.selected_protocols) {
          selectedprotocols.push(...hospital.selected_protocols.split(','));
        }
      });

    let distinctSelectedOption = new Set([...(selectedprotocols.join(',')).split(',')]);

    let unSelectedprotocols: string[] = [];
    currentProtocol?.forEach(
      (hospital: IHospitalProtocol) => {
        if (hospital?.unselected_protocols) {
          unSelectedprotocols.push(...hospital.unselected_protocols.split(','));
        }
      });

    let distinctUnselectedOption = new Set([...(unSelectedprotocols.join(',')).split(',')]);

    const payload = {
      hospitalID: this.headerSearch.makeSelectLastNodeId(),
      page: url,
      selected_protocols: Array.from(distinctSelectedOption).join(','),
      unselected_protocols: Array.from(distinctUnselectedOption).join(',')
    };
    return this.httpClient.put(`${API_NAME.saveProtocol}`, payload);
  }

  removeProtocolFromArray(oldProtocol: string, updatedProtocol: string) {
    let oldProtocolList = oldProtocol?.split(',');
    let updatedProtocolList = updatedProtocol?.split(',');

    updatedProtocolList?.forEach((paramProtocol: string) => {
      const index = oldProtocolList.indexOf(paramProtocol);
      if (index !== -1) {
        oldProtocolList.splice(index, 1);
      }
    });
    return oldProtocolList.join(',');
  }

  addProtocolFromArray(oldProtocol: string, updatedProtocol: string) {
    return oldProtocol ? `${oldProtocol},${updatedProtocol}` : updatedProtocol;
  }

  checkPermission(permissionName: string) {
    let permissions = this.getPermission();

    if (!this.isCheckObject(permissions)) {
      return false;
    }

    const filterObject = permissions.find(
      (lable) =>
        lable?.toLocaleLowerCase() === permissionName?.toLocaleLowerCase()
    );
    if (!filterObject) {
      return false;
    }

    return true;
  }

  toCheckAdminMenuAccess() {
    const role = this.getUserRole();

    if (!role) {
      return false;
    }

    if (role?.toLocaleLowerCase() === USER_ROLE?.user?.toLocaleLowerCase()) {
      return false;
    }

    return true;
  }
  toCheckUserRoleIsAdmin() {
    const role = this.role();
    if (!role) {
      return false;
    }

    if (role?.toLocaleLowerCase() === USER_ROLE.super_admin?.toLocaleLowerCase()) {
      return true;
    }


    return false;
  }
  toCheckAdminAccessGuard(roles: string[]) {
    if (!roles) {
      return false;
    }
    const currentUserRole = this.getUserRole();
    const filterRole = roles.find(
      (role: string) =>
        role?.toLocaleLowerCase() === currentUserRole?.toLocaleLowerCase()
    );

    if (!filterRole) {
      return false;
    }
    return true;
  }

  getUserRoleOption() {
    const userOptions = [...USER_ROLE_OPTION];
    const filteredUserRole = userOptions.filter((option: IUserOption) => {
      return this.toCheckRolePermission(option);
    }) as IUserOption[];

    return filteredUserRole;
  }

  private toCheckRolePermission(option: IUserOption) {
    let splitedPermission = option?.permission?.toLocaleLowerCase().split(',');
    return splitedPermission.includes(this.getUserRole()?.toLocaleLowerCase());
  }

  isAllowedRegion(regionName: string) {
    if (this.role()?.toLocaleLowerCase() === USER_ROLE.super_admin?.toLocaleLowerCase()) {
      return true;
    }
    const shortRegion = this.regionWithShortName[regionName];
    const allowedRegions = this.getAllowedRegion();
    const findRegion = allowedRegions?.find((region: string) => {
      return region?.toLocaleLowerCase() === shortRegion?.toLocaleLowerCase();
    });

    if (findRegion) {
      return true;
    }
    return false;
  }

  toCheckAdminRole() {
    const role = this.getUserRole()?.toLocaleLowerCase();
    return role !== USER_ROLE['user']?.toLocaleLowerCase();
  }

  userRoleConversion(role: string) {
    if (!role) {
      return '';
    }

    return CONVERSION_USER_ROLE[role] ?? ''
  }

}
